<template>
  <div class="container-fluid not-found-page p-tb-2">
    <div class="container">
      <div class="heading">
        <span>OOPS...</span>
        <h1>Page Not Found</h1>
      </div>
      <div class="code-wrapper">
        <div class="error-code">404</div>
      </div>
      <div class="contact-us">
        <div class="info-handler">
          <div class="top-line">
            <div>99<span class="percent-sign">%</span></div>
            <div>
              <span>of VaporFly</span>
              <span>customers will</span>
              <span>never see this page.</span>
            </div>
          </div>
          <div class="medium-line">But you're the special one.</div>
          <div class="bottom-line">
            <span>Send this form and get the special price</span>
            <span>for your order!</span>
          </div>
        </div>
        <div class="form-handler">
          <ContactUsForm :request-types="types" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUsForm from 'Components/ContactUsForm';
import Services from 'Lib/enums/Services';
import { updateMeta } from 'Lib/Utils';

export default {
  name: 'NotFound',
  components: { ContactUsForm },
  computed: {
    services() {
      return Services;
    },
    types() {
      return this.services.map(el => {
        return { text: el.title, value: el.title };
      });
    },
  },
  beforeMount() {
    const page = this.$vfPages.get('404');
    if (page) {
      updateMeta(page.metaInfo);
    }
  },
};
</script>

<style type="text/css" lang="scss">
.not-found-page {
  .heading {
    text-align: center;
    text-transform: uppercase;

    span {
      font-size: 2rem;
      font-weight: 500;
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: small;
    }
  }
  .code-wrapper {
    text-align: center;

    .error-code {
      font-size: 20rem;
      font-weight: bold;
      color: #e7e7e7;
    }
  }
  .contact-us {
    .info-handler {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 500;
      align-self: center;

      .top-line {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > div {
          flex-basis: 50%;
        }
        & > :nth-child(1) {
          font-size: 5rem;
          font-weight: 500;
          text-align: right;
          padding-right: 5px;

          .percent-sign {
            font-size: 2.5rem;
          }
        }
        & > :nth-child(2) {
          flex-direction: column;
          padding-left: 5px;

          span {
            font-size: smaller;
            display: inline-block;
            width: 100%;
          }
        }
      }
      .medium-line {
        text-align: center;
        font-size: 1.2rem;
      }
      .bottom-line {
        text-align: center;
        span {
          display: inline-block;
          width: 100%;
        }
        font-size: 0.78rem;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .code-wrapper .error-code {
    font-size: 10rem !important;
    margin: 1rem 0;
  }
  .contact-us {
    flex-direction: column !important;
    .info-handler {
      margin-bottom: 1rem;
    }

    & > div {
      flex-basis: 100% !important;
    }
  }
}
</style>
